import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import OwnRentContent from '../components/modals/OwnRentContent';
import { storeReferralData } from '../utils/helpers';
import SEO from '../components/SEO';

const OwnVsRentPage = ({ location }) => {
  // store referral params
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
    }
  }, []);
  return (
    <>
      <SEO title='Own or Rent' />
      <Layout>
        <Container>
          <OwnRentContent />
        </Container>
      </Layout>
    </>
  );
};
const Container = styled.section`
  padding: var(--body-padding-y) var(--body-padding-x);
`;

export default OwnVsRentPage;
